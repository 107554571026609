import http from "./http";

// 用户登录
export function getLoginStatus(params) {
  return http.get("/user/loginAndAuth", { params });
}

//七牛云获取token
export function QINIU_GET_TOKEN(params) {
  return http.get("/qiniu/getToken", { params });
}
