<template>
  <div></div>
  <!-- <div class="tabs">
        <el-tag 
        v-for="(item , index) in tags" 
        :key="item.path" 
        :closable="item.name !== 'home'"
        @click = "changeMenu(item)"
        @close = "handleClose(item,index)"
        size="small"
        :effect="$route.name === item.name ? 'dark' : 'plain'">
            {{ item.label }}
        </el-tag>
    </div> -->
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "CommonTag",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      tags: (state) => state.tab.tabsList,
    }),
  },
  methods: {
    //点击tag 跳转的功能
    ...mapMutations(["closeTag"]),
    changeMenu(item) {
      this.$router.push({ name: item.name });
    },
    handleClose(item, index) {
      this.closeTag(item);
      const length = this.tags.length;
      //删除之后的跳转逻辑
      //要删除的标签和当前所在页面不一样
      if (item.name !== this.$route.name) {
        return;
      } else {
        //要删除的标签就是当前页面

        //当前页面处于最后一个标签
        if (index === length) {
          this.$router.push({
            name: this.tags[index - 1].name,
          });
        } else {
          //跳转到后续的标签中
          this.$router.push({
            name: this.tags[index].name,
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tabs {
  padding: 20px;
  .el-tag {
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>
