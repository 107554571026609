<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
    :unique-opened="true"
  >
    <h3 class="big-title">{{ isCollapse ? "后台" : "海外后台管理系统" }}</h3>
    <el-menu-item
      v-for="(menu, index) in nosubMenus"
      :key="index"
      @click="gotoPath(menu)"
      :index="menu.path"
    >
      <div class="sub-menu-title">
        <i :class="menu.icon"></i>
        <span>{{ menu.label }}</span>
      </div>
    </el-menu-item>
    <el-submenu
      v-for="(menu, index2) in subMenus"
      :key="index2"
      :index="String(index2)"
    >
      <template slot="title">
        <div class="sub-menu-title">
          <i :class="menu.icon"></i>
          <span>{{ menu.label }}</span>
        </div>
      </template>
      <el-menu-item
        v-for="(child, childIndex) in menu.children"
        :key="childIndex + '1'"
        :index="child.path"
        @click="gotoPath(child)"
        class="menu-item-sub"
      >
        {{ child.label }}
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: "/home", // Default active menu item
      //   menuRoute: [
      //     {
      //       label: "首页",
      //       path: "/home",
      //       icon: "el-icon-s-data",
      //       authKey: "home",
      //     },
      //     {
      //       label: "错误日志",
      //       path: "/logManage/index",
      //       icon: "el-icon-camera",
      //       authKey: "logManage",
      //     },
      //     {
      //       label: "APP版本",
      //       path: "/appManage/index",
      //       icon: "el-icon-mobile",
      //       authKey: "appManage",
      //     },
      //     {
      //       label: "固件版本",
      //       path: "/firmWareManage/index",
      //       icon: "el-icon-mouse",
      //       authKey: "firmWareManage",
      //     },
      //     {
      //       label: "白名单管理",
      //       path: "/allowListManage/index",
      //       icon: "el-icon-s-check",
      //       authKey: "allowListManage",
      //     },
      //     {
      //       label: "APP官方公告管理",
      //       path: "/appBulletinManage/index",
      //       icon: "el-icon-s-flag",
      //       authKey: "appBulletinManage",
      //     },
      //     {
      //       label: "教学视频",
      //       path: "/tutorialManage/index",
      //       icon: "el-icon-film",
      //       authKey: "tutorialManage",
      //     },
      //     {
      //       label: "曲谱通用配置",
      //       path: "/settingManage/index",
      //       icon: "el-icon-share",
      //       authKey: "settingManage",
      //     },
      //     {
      //       label: "用户反馈",
      //       path: "/appUserFeedbackManage/index",
      //       icon: "el-icon-bell",
      //       authKey: "appUserFeedbackManage",
      //     },

      //     {
      //       label: "用户管理",
      //       path: "",
      //       icon: "el-icon-user",
      //       authKey: "userManage",

      //       children: [
      //         { label: "普通用户", path: "/userManage/index" },
      //         { label: "马甲用户", path: "/userManage/pretendUser" },
      //       ],
      //     },
      //     {
      //       label: "拨片/鼓机管理",
      //       path: "",
      //       icon: "el-icon-headset",
      //       hasAuth: true,
      //       authKey: "pickAndDrumManage",
      //       children: [
      //         { label: "拨片", path: "/pickManage/index" },
      //         { label: "鼓机", path: "/drumManage/index" },
      //       ],
      //     },
      //     {
      //       label: "曲谱管理",
      //       path: "",
      //       icon: "el-icon-coin",
      //       authKey: "songManage",
      //       children: [
      //         { label: "官方曲谱", path: "/songManage/allSong" },
      //         { label: "曲谱分类", path: "/songManage/songCategoryEdit" },
      //         { label: "曲谱报错", path: "/songManage/songError" },
      //         { label: "曲谱举报", path: "/appUserReportManage/index" },
      //         { label: "自定义曲谱", path: "/songManage/songCustom" },
      //         { label: "标签管理", path: "/songManage/tagMusic" },
      //         { label: "曲谱标签", path: "/songManage/tag" },
      //         { label: "曲谱搜不到", path: "/songManage/unfoundSongs" },
      //       ],
      //     },
      //   ],
      menuRoute: [],
    };
  },
  mounted() {
    if (this.$store.state.tab.menu.length) {
      this.menuRoute = this.$store.state.tab.menu;
    } else {
      this.menuRoute = JSON.parse(localStorage.getItem("menu"));
    }
  },
  computed: {
    subMenus() {
      return this.getMenuAuth.filter((menu) => menu.children);
    },
    nosubMenus() {
      return this.getMenuAuth.filter((menu) => !menu.children);
    },
    isCollapse() {
      return this.$store.state.tab.isCollapse;
    },
    getMenuAuth() {
      return this.menuRoute;
    },
  },
  methods: {
    gotoPath(data) {
      if (this.$route.path !== data.path) {
        // 只能不是当前路由，才会跳转
        this.$router.push(data.path);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu {
  height: 100vh;
  border-right: none;

  h3 {
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
}
.sub-menu-title {
  span {
    font-size: 15px;
    font-weight: 600;
  }
}
.menu-item-sub {
  padding-left: 48px !important;
  font-weight: 600 !important;
}
.el-menu {
  .big-title {
    font-size: 16px;
    font-weight: 600;
  }
}
</style>
