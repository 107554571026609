import Cookie from "js-cookie";

export default {
  //存放本地数据的地方
  state: {
    drumOptions: [], //鼓机列表
    pickOptions: [], //拨片列表
  },
  mutations: {
    SET_DRUM_OPTIONS: (state, data) => {
      state.drumOptions = data;
    },
    SET_PICK_OPTIONS: (state, data) => {
      state.pickOptions = data;
    },
  },
};
