import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router"; //这种写法会默认到  router文件夹下的index.js去找
import store from "./store";
// import "./api/mock";

import "./assets/styles/global.scss";

import Cookie from "js-cookie";
import prototype from "./utils/prototype";
Vue.config.productionTip = false;
import moment from "moment";
Vue.prototype.$moment = moment;
import Pagination from "@/components/CommonTable/Pagination.vue";
import SubmitForm from "@/components/CommonTable/SubmitForm/index.vue";
import TableDisplayColumn from "@/components/CommonTable/TableDisplayColumn.vue";
import CommonTable from "@/components/CommonTable/index.vue";
Vue.component("Pagination", Pagination);
Vue.component("SubmitForm", SubmitForm);
Vue.component("TableDisplayColumn", TableDisplayColumn);
Vue.component("CommonTable", CommonTable);
Vue.use(ElementUI);
Vue.use(prototype);

//添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  //token存不存在
  const token = Cookie.get("token");
  //token不存在  说明当前用户是未登录，应该跳转到登录页
  if (!token && to.name !== "login") {
    next({ name: "login" });
  } else if (token && to.name === "login") {
    //token存在  说明用户已经登录,此时跳转至首页
    next({ name: "home" });
  } else {
    next();
  }
});

//页面刷新  vue实例会初始化  重新创建
new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    // store.commit("addMenu", router);
  },
}).$mount("#app");

// 去掉在浏览器中  所有的 [Vue warn]
Vue.config.silent = true;
