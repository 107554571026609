/*
 * @Author: xzj
 * @Date: 2021-05-07 09:49:44
 * @Description: 所有的api接口归到这里，统一管理
 *
 * 接口管理规范：
 * 1、所有的接口写在一个对象中导出；
 * 2、每一个接口控制两个变量，请求方法和url地址；
 * 3、同模块不同功能之间空一行，注释跟在行后,不同功能模块之间用单独一行的注释隔开，且注释中用---拉长；
 * 4、接口名称全部使用大写字母，多个单词之间用下划线连接；
 * 5、接口名字的命名规则以后台的react规范的方式命名，如：list，retrieve，create...
 * 6、放弃put方法，全面使用patch方法来update后台数据；
 *
 */
import http from "../utils/request.js";

export default {
  // 登录
  LOGIN: (params) => http.post("/login/", params),
  LOGIN_AND_AUTH: (name, password) =>
    http.get(`/user/loginAndAuth?name=${name}&password=${password}`),

  // 上传token
  UPLOAD_TOKEN: () => http.get("/token/upload"),

  // 曲谱
  SONG_SEARCH: (params) => http.get("/song/platformSearch", { params }),
  SONG_InsertOrUpdate: (params) => http.post("/song/insertOrUpdate", params),
  SONG_DETAIL: (params) => {
    return http.get(`/song`, params);
  },
  SONG_PUBLISH: (songId) => {
    return http.post(`/song/publish?songId=${songId}`);
  },
  SONG_UN_PUBLISH: (songId, unPublishStatus) => {
    return http.post(
      `/song/unPublish?songId=${songId}&unPublishStatus=${unPublishStatus}`
    );
  },
  SONG_DELETE: (songId) => http.post(`/song/delete?songId=${songId}`),
  SONG_OF_CATEGORY: (categoryId) => {
    return http.get(`/complexSong/allSongOfCategory?categoryId=${categoryId}`);
  },
  SONG_SEARCH_NO_PAGE: (songId, keyWord, status) => {
    return http.get(
      `/complexSong/platformSearchNoPage?songId=${songId}&keyWord=${keyWord}&status=${status}`
    );
  },
  SONG_MUTI_IMPORT: (params) =>
    http.post("/complexSong/mutiImportSongs", params),
  SONG_OF_TAG: (tagId) => {
    return http.get(`/song/allSongOfTag?tagId=${tagId}`);
  },
  SONG_BELONG: (pUserId) => {
    return http.get(`/song/belong?pUserId=${pUserId}`);
  },

  //曲谱错误
  SONG_ERROR_SEARCH: (params) =>
    http.get("/songError/platformSearch", { params }),
  SONG_ERROR_READED: (id) => http.post(`/songError/readed?id=${id}`),
  SONG_ERROR_RESOLVED: (id) => http.post(`/songError/resolved?id=${id}`),
  SONG_ERROR_REJECT: (id) => http.post(`/songError/reject?id=${id}`),

  // 曲谱分类
  SONG_CATEGORY_LIST: () => http.get(`/complexSong/category`),
  SONG_CATEGORY_MUTISAVE: (params) =>
    http.post("/song/multiSaveCategory", params),

  // 乐器选项
  MUSICAL_INSTRUMENT_LIST: (params) =>
    http.get("/instrument/getAll", { params }),
  MUSICAL_INSTRUMENT_CREATE: (params) =>
    http.get("/musical-instrument/", { params }),
  MUSICAL_INSTRUMENT_DELETE: (pk) => http.get(`/musical-instrument/${pk}/`),

  //音乐标签
  TAG_MUSIC_TREE: () => http.get("/tagMusic/getTree"),
  TAG_MUSIC_ADD: (params) => http.post("/tagMusic/add", params),
  TAG_MUSIC_UPDATE: (params) => http.post("/tagMusic/update", params),
  TAG_MUSIC_DELETE: (id) => http.post(`/tagMusic/delete?id=${id}`),
  TAG_MUTISAVE: (params) => http.post("/song/multiSaveTag", params),

  // 拨片节奏型
  PICK_SEARCH: (params) => http.post("/rhythmic/pick/platformSearch", params),
  PICK_CATEGORY_TREE: () => http.get("/rhythmic/pickCategory/getTree"),
  RHYTHMIC_PICK_CATEGORY_LIST: (params) =>
    http.get("/rhythmic/pick/getTree", { params }),
  RHYTHMIC_PICK_INSERT_OR_UPDATE: (params) =>
    http.post("/rhythmic/pick/insertOrUpdate", params),
  RHYTHMIC_PICK_PUBLISH: (pickId) =>
    http.post(`/rhythmic/pick/publish?pickId=${pickId}`),
  RHYTHMIC_PICK_OFFSHELF: (pickId) =>
    http.post(`/rhythmic/pick/offShelf?pickId=${pickId}`),
  RHYTHMIC_PICK_DELETE: (pickId) =>
    http.post(`/rhythmic/pick/delete?pickId=${pickId}`),

  // 鼓机
  DRUM_LIST: (params) => http.get("/rhythmic/drum/getAll", { params }),
  DRUM_SEARCH: (params) => http.post("/rhythmic/drum/platformSearch", params),
  DRUM_CATEGORY_LIST: () => http.get("/rhythmic/drum/getCategory"),
  DRUM_INSERT_OR_UPDATE: (params) =>
    http.post("/rhythmic/drum/insertOrUpdate", params),
  DRUM_PUBLISH: (drumId) =>
    http.post(`/rhythmic/drum/publish?drumId=${drumId}`),
  DRUM_OFFSHELF: (drumId) =>
    http.post(`/rhythmic/drum/offShelf?drumId=${drumId}`),
  DRUM_DELETE: (drumId) => http.post(`/rhythmic/drum/delete?drumId=${drumId}`),

  //教学视频
  TUTORIAL_SEARCH: (params) => http.post("/tutorial/platformSearch", params),
  TUTORIAL_CATEGORY_LIST: () => http.get("/tutorial/getCategory"),
  TUTORIAL_INSERT_OR_UPDATE: (params) =>
    http.post("/tutorial/insertOrUpdate", params),
  TUTORIAL_PUBLISH: (id) => http.post(`/tutorial/publish?tutorialId=${id}`),
  TUTORIAL_OFFSHELF: (id) => http.post(`/tutorial/offShelf?tutorialId=${id}`),
  TUTORIAL_DELETE: (id) => http.post(`/tutorial/delete?tutorialId=${id}`),

  //App版本
  VERSION_SEARCH: (params) => http.post("/version/platformSearch", params),
  VERSION_INSERT_OR_UPDATE: (params) =>
    http.post("/version/insertOrUpdate", params),
  VERSION_PUBLISH: (id) => http.post(`/version/publish?versionId=${id}`),
  VERSION_OFFSHELF: (id) => http.post(`/version/offShelf?versionId=${id}`),
  VERSION_DELETE: (id) => http.post(`/version/delete?versionId=${id}`),

  //设备固件
  VERSION_DEVICE_SEARCH: (params) =>
    http.post("/versionDevice/platformSearch", params),
  VERSION_DEVICE_INSERT_OR_UPDATE: (params) =>
    http.post("/versionDevice/insertOrUpdate", params),
  VERSION_DEVICE_PUBLISH: (id) => http.post(`/versionDevice/publish?id=${id}`),
  VERSION_DEVICE_OFFSHELF: (id) =>
    http.post(`/versionDevice/offShelf?id=${id}`),
  VERSION_DEVICE_DELETE: (id) => http.post(`/versionDevice/delete?id=${id}`),

  //用户
  USER_SEARCH: (params) => http.post("/user/platformSearch", params),
  USER_EXTERNAL: (userId) =>
    http.get(`/thirdExternal/getByUserId?userId=${userId}`),
  USER_DEVICEVO: (userId) =>
    http.get(`/device/getUserPDeviceVo?userId=${userId}`),
  USER_PLAYLOG: (params) => http.get("/playLog/platformSearch", { params }),
  USER_CUSTOMSONG: (params) =>
    http.get("/complexSong/custom/platformSearchCustom", { params }),
  USER_ACCESSTOKEN: (params) =>
    http.get("/accessToken/platformSearch", { params }),
  USER_UPDATE: (params) => http.post("/user/updateUserInfo", params),
  USER_UPDATE_REMARK: (params) => http.post("/user/updateRemark", params),

  //马甲用户
  PRETEND_USER_SEARCH: (params) =>
    http.post("/pretendUser/platformSearch", params),

  //白名单
  WHITE_SEARCH: (params) =>
    http.get("/whiteBlack/whitePlatformSearch", { params }),
  WHITE_INSERT: (params) =>
    http.post(
      `/whiteBlack/addWhite?mobile=${params.mobile}&remark=${params.remark}&pUserId=${params.pUserId}`
    ),
  WHITE_REMOVE: (mobile) =>
    http.post(`/whiteBlack/removeWhite?mobile=${mobile}`),

  //通用配置
  COMMON_SETTING_SEARCH: (params) =>
    http.post("/sysCommonSetting/platformSearch", params),
  COMMON_SETTING_INSERT_OR_UPDATE: (params) =>
    http.post("/sysCommonSetting/insertOrUpdate", params),
  COMMON_SETTING_PUBLISH: (id) =>
    http.post(`/sysCommonSetting/publish?id=${id}`),
  COMMON_SETTING_OFFSHELF: (id) =>
    http.post(`/sysCommonSetting/unPublish?id=${id}`),
  COMMON_SETTING_DELETE: (id) => http.post(`/sysCommonSetting/delete?id=${id}`),
  COMMON_SETTING_DETAIL: (id) => http.get(`/sysCommonSetting/detail?id=${id}`),

  // appError报错
  APPERROR_SEARCH: (params) => http.get("/appError/platformSearch", { params }),
  APPERROR_DETAIL: (id) => http.get(`/appError/getById?id=${id}`),

  // appWrong系统优化
  APPWRONG_SEARCH: (params) => http.post("/appWrong/platformSearch", params),
  APPWRONG_READED: (id, pUserId) =>
    http.post(`/appWrong/readed?id=${id}&pUserId=${pUserId}`),
  APPWRONG_RESOLVED: (id, pUserId) =>
    http.post(`/appWrong/resolved?id=${id}&pUserId=${pUserId}`),
  APPWRONG_REJECT: (id, pUserId) =>
    http.post(`/appWrong/reject?id=${id}&pUserId=${pUserId}`),
  APPWRONG_REMARK: (id, remark) =>
    http.post(`/appWrong/updateRemark?id=${id}&remark=${remark}`),

  // 设备
  DEVICE_SEARCH: (params) => http.get("/device/platformSearch", { params }),
  DEVICE_UNBIND: (params) => http.get("/device/unbind", { params }),

  //七牛云获取token
  QINIU_GET_TOKEN: (params) => http.get("/qiniu/getToken", { params }),

  //渠道
  CLIENT_GETALL: () => http.get("/client/getAll"),

  //看板
  BOARD_STATISTICS1: () => http.get("/board/statistics1"),
  BOARD_PIE2: () => http.get("/board/pie2"),
  BOARD_STATISTICS2: (params) => http.get("/board/statistics2", { params }),
  BOARD_STATISTICS3: (params) => http.get("/board/statistics3", { params }),
  BOARD_STATISTICS4: (params) => http.get("/board/statistics4", { params }),
  BOARD_STATISTICS5: (params) => http.post("/board/statistics5", params),
  BOARD_STATISTICS6: (params) => http.get("/board/statistics6", { params }),
  BOARD_STATISTICS7: (params) => http.get("/board/statistics7", { params }),
  BOARD_STATISTICS8: (params) => http.get("/board/statistics8", { params }),
  // BOARD_STATISTICS5: (params) => http.get('/board/statistics5', { params }),
  BOARD_RECORDOLDESTDATE: () => http.get("/board/getRecordOldestDate"),

  //tipoff
  TIPOFF_SEARCH: (params) => http.post("/tipOff/platformSearch", params),
  TIPOFF_READED: (id, pUserId) =>
    http.post(`/tipOff/readed?id=${id}&pUserId=${pUserId}`),
  TIPOFF_HANDLING: (id, pUserId) =>
    http.post(`/tipOff/handling?id=${id}&pUserId=${pUserId}`),
  TIPOFF_HANDLED: (id, pUserId) =>
    http.post(`/tipOff/handled?id=${id}&pUserId=${pUserId}`),
  TIPOFF_REJECT: (id, pUserId) =>
    http.post(`/tipOff/reject?id=${id}&pUserId=${pUserId}`),
  TIPOFF_REMARK: (id, remark) =>
    http.post(`/tipOff/updateRemark?id=${id}&remark=${remark}`),

  //官方公告
  NOTICE_SEARCH: (params) => http.post("/notice/platformSearch", params),
  NOTICE_DETAIL: (id) => http.get(`/notice/detail?id=${id}`),
  NOTICE_INSERT_OR_UPDATE: (params) =>
    http.post("/notice/insertOrUpdate", params),
  NOTICE_PUBLISH: (id) => http.post(`/notice/publish?id=${id}`),
  NOTICE_OFFSHELF: (id) => http.post(`/notice/unPublish?id=${id}`),
  NOTICE_DELETE: (id) => http.post(`/notice/delete?id=${id}`),

  // 自定义曲谱
  CUSTOM_SONG_SEARCH: (params) =>
    http.get("/complexSong/custom/platformSearchCustom", { params }),
  CUSTOM_SONG_DETAIL: (songId) =>
    http.get(`/customSong/platformDetail?songId=${songId}`),
  CUSTOM_SONG_PUBLISH: (songId) =>
    http.post(`/customSong/publish?songId=${songId}`),
  CUSTOM_SONG_UN_PUBLISH: (songId, unPublishStatus) =>
    http.post(
      `/customSong/unPublish?songId=${songId}&unPublishStatus=${unPublishStatus}`
    ),
  CUSTOM_SONG_DELETE: (userId, songId) =>
    http.post(`/customSong/delete?userId=${userId}&songId=${songId}`),
  CUSTOM_SONG_InsertOrUpdate: (params) =>
    http.post("/customSong/insertOrUpdate", params),

  // 提示tip
  TIP_SEARCH: (params) => http.post("/tip/platformSearch", params),
  TIP_PUBLISH: (id, pUserId) =>
    http.post(`/tip/publish?id=${id}&pUserId=${pUserId}`),
  TIP_OFFSHELF: (id, pUserId) =>
    http.post(`/tip/offShelf?id=${id}&pUserId=${pUserId}`),
  TIP_DELETE: (id) => http.post(`/tip/delete?id=${id}`),
  TIP_INSERTORUPDATE: (params) => http.post("/tip/insertOrUpdate", params),

  NO_SEARCH_KEYS: (type) => http.get(`/noSearch/getKeys?type=${type}`),
  NO_SEARCH_BOARD: (type, key) =>
    http.get(`/noSearch/board?type=${type}&key=${key}`),
};
