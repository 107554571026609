var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "login-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "login-form",
          attrs: {
            "label-width": "70px",
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
          },
        },
        [
          _c("h3", { staticClass: "login_title" }, [
            _vm._v("海外管理后台系统登录"),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "用户名", prop: "username" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入账号" },
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "密码", prop: "password" } },
            [
              _c("el-input", {
                attrs: { type: "password", placeholder: "请输入密码" },
                model: {
                  value: _vm.form.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "password", $$v)
                  },
                  expression: "form.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "105px", "margin-top": "10px" },
                  attrs: {
                    type: "primary",
                    disabled: !(_vm.form.username && _vm.form.password),
                  },
                  on: { click: _vm.handleRegister },
                },
                [_vm._v("登录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }