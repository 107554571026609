var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-menu",
    {
      staticClass: "el-menu-vertical-demo",
      attrs: {
        "default-active": "1-4-1",
        collapse: _vm.isCollapse,
        "background-color": "#545c64",
        "text-color": "#fff",
        "active-text-color": "#ffd04b",
        "unique-opened": true,
      },
    },
    [
      _c("h3", { staticClass: "big-title" }, [
        _vm._v(_vm._s(_vm.isCollapse ? "后台" : "海外后台管理系统")),
      ]),
      _vm._l(_vm.nosubMenus, function (menu, index) {
        return _c(
          "el-menu-item",
          {
            key: index,
            attrs: { index: menu.path },
            on: {
              click: function ($event) {
                return _vm.gotoPath(menu)
              },
            },
          },
          [
            _c("div", { staticClass: "sub-menu-title" }, [
              _c("i", { class: menu.icon }),
              _c("span", [_vm._v(_vm._s(menu.label))]),
            ]),
          ]
        )
      }),
      _vm._l(_vm.subMenus, function (menu, index2) {
        return _c(
          "el-submenu",
          { key: index2, attrs: { index: String(index2) } },
          [
            _c("template", { slot: "title" }, [
              _c("div", { staticClass: "sub-menu-title" }, [
                _c("i", { class: menu.icon }),
                _c("span", [_vm._v(_vm._s(menu.label))]),
              ]),
            ]),
            _vm._l(menu.children, function (child, childIndex) {
              return _c(
                "el-menu-item",
                {
                  key: childIndex + "1",
                  staticClass: "menu-item-sub",
                  attrs: { index: child.path },
                  on: {
                    click: function ($event) {
                      return _vm.gotoPath(child)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(child.label) + " ")]
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }