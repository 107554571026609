<template>
  <div class="login-container">
    <el-form
      ref="form"
      label-width="70px"
      class="login-form"
      :inline="true"
      :model="form"
      :rules="rules"
    >
      <h3 class="login_title">海外管理后台系统登录</h3>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="form.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="handleRegister"
          type="primary"
          :disabled="!(form.username && form.password)"
          style="margin-left: 105px; margin-top: 10px"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Cookie from "js-cookie";
import { getLoginStatus } from "@/services/api.js";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
      },
    };
  },
  methods: {
    //登录
    handleRegister() {
      //表单校验通过
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loginIt();
        }
      });
    },
    async loginIt() {
      const res = await getLoginStatus({
        name: this.form.username,
        password: this.form.password,
      });
      if (res?.code === 1) {
        //token信息存入cookie用于不同页面间的通信
        Cookie.set("token", res.data.token);
        //路由菜单数据存入store中
        this.$store.commit("setMenu", res.data.menus);
        // permissionNames存入store中
        const permissionNames = res.data?.permissions.map(
          (permission) => permission.ename
        );

        this.$store.commit("setPermissions", permissionNames);
        this.$store.commit("setUser", res.data?.name);
        localStorage.setItem("pUserName", res.data?.name);
        // this.$store.commit("addMenu", this.$router); //干嘛的？
        this.$store.commit("setPUserId", res.data?.userId); //为什么？
        if (res.data?.root) {
          // root权限，有一些操作可以执行
          this.$router.push("/home");
          Cookie.set("root", true);
        } else {
          // 非root权限，有一些操作比如编辑曲谱，只有那个人创建的才可以编辑
          if (res.data?.menus[0]?.path) {
            //如果是只有一级路由，就去一级路由
            this.$router.push(res.data?.menus[0]?.path);
          } else {
            //如果有二级路由，去二级路由的第一个子路由
            if (res.data?.menus[0]?.children[0]?.path) {
              this.$router.push(res.data?.menus[0]?.children[0]?.path);
            }
          }
          Cookie.set("root", false);
        }
      }
    },
  },
};
</script>

<style lang="less">
.login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .login-form {
    width: 400px;
    border: 1px solid #eaeaea;
    //   margin: 180px auto;
    padding: 35px 35px 15px 35px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 25px #cac6c6;
    box-sizing: border-box;
    .login_title {
      text-align: center;
      margin-bottom: 40px;
      color: #505458;
    }
    .el-input {
      width: 198px;
    }
  }
}
</style>
