import axios from "axios";
import { Message } from "element-ui";

// 创建 axios 实例
const service = axios.create({
  //   baseURL: process.env.VUE_APP_API_BASE_URL, // 基础 API 地址--国内
  baseURL: process.env.VUE_APP_OVERSEAS_API_BASE_URL, // 基础 API 地址

  timeout: 15000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在请求头中添加 puserid
    const puserId = localStorage.getItem("pUserId");
    config.headers["puserId"] = puserId;
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 如果后端定义了错误码，这里可以处理
    if (res.code !== 1) {
      Message.error(res.msg);
    } else {
      return res;
    }
  },
  (error) => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default service;
